//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-272:_228,_280,_6556,_3184,_3316,_2600,_3340,_9504;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-272')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-272', "_228,_280,_6556,_3184,_3316,_2600,_3340,_9504");
        }
      }catch (ex) {
        console.error(ex);
      }