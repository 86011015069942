const baseFitmentFields = ['Make', 'Model', 'Year', 'Submodel', 'Engine'];
const extraFitmentFields = [];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields, 'Vehicle'];

const categoryPageSelector = 'body.page-category';
const isCategoryPage = window.document.querySelector(categoryPageSelector);

window.Convermax.showConvermaxResults =
  window.document.querySelectorAll('#product-listing-container .product').length === 0 ||
  window.document.body.classList.contains('cm_search');

function getLocalPreselection(pageType, defaults) {
  if (pageType === 'category' && window.location.pathname.endsWith('.htm')) {
    return [
      {
        field: 'category_url',
        term: window.location.pathname,
      },
    ];
  }
  return defaults.getter(pageType);
}

function responseHandler(response) {
  const { VehicleCategoryRedirectUrl } = response.Extra;

  if (VehicleCategoryRedirectUrl) {
    window.location.assign(VehicleCategoryRedirectUrl);
    return { ...response, Actions: [] };
  }

  if (window.document.body.classList.contains('page-category')) {
    const originalCategoryListing = window.document.querySelector(
      'body.page-category .page .page-content #product-listing-container',
    );

    const { selection, query, sort, pageSize } = window.Convermax.getSearchRequest();
    const isVehicleSelected = window.Convermax.isVehicleSelected();

    if (
      !window.Convermax.showConvermaxResults &&
      window.document.body.classList.contains('cm_search-hide') &&
      (isVehicleSelected ||
        selection.length > 0 ||
        query.length > 0 ||
        sort !== 'relevance' ||
        pageSize !== 24 ||
        window.document.querySelector('.cmTemplate_timeout') ||
        window.document.querySelector('.cmTemplate_networkError'))
    ) {
      if (originalCategoryListing) {
        originalCategoryListing.style.display = 'none';
      }

      window.document.body.classList.remove('cm_search-hide');

      window.Convermax.showConvermaxResults = true;
    }
  }

  return response;
}

function InitFunc() {
  if (window.document.body.classList.contains('page-category')) {
    window.document.body.classList.add('cm_search-hide');
  }
}

export default {
  platform: 'bigcommerce',
  InitFunc,
  getLocalPreselection,
  defaultView: window.innerWidth > 1200 ? 'grid-view' : 'list-view',
  responseHandler,
  getSearchRequestDefaults: (pageType) =>
    // consider pages with search results
    // as regular Category Search Results Pages and not Langing Pages
    pageType === 'category' && window.document.querySelector('#cm_results')
      ? { extra: { categoryPageWithSearchResults: true, pageType } }
      : { extra: { pageType } },
  SearchRequestDefaults: {
    pageSize: 24,
  },
  page: {
    typeToSelectorMap: {
      category: categoryPageSelector,
    },
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    expectResponseRedirect: true,
    // prevent category redirect with partial vehicle selection
    isAutoVehicleSelectionDisabled: isCategoryPage,
    isVehicleSelectionIsolated: isCategoryPage,
    hideVehicleFromUrl: true,
  },
  facets: {
    rangedFacet: [{ fields: ['wheel_bore'], step: '1/10//1', slider: { mode: 'LowerBound' } }],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: 'body:not(.page-category) #cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: { insertAfter: 'body.page-category .page .page-content .categoryPage-searchInCategory-form' },
      template: 'CategoryPage',
    },
    {
      // categories first and second level doesn't have our widget
      // but the request, sended on these pages, must be not "isolated"
      // because preselection is needed for the category redirections
      name: 'StubSearchPage',
      type: 'SearchPage',
      location: { insertAfter: '.category-description' },
      template: 'StubSearchPage',
      visibleIf: () => !window.document.querySelector('#cm_results'),
    },
    {
      name: 'SearchPageTitle',
      type: 'VehicleWidget',
      location: { replace: 'h1.page-heading' },
      template: 'fitmentSearch/pageTitle',
      visibleIf: { urls: ['/search/'] },
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'price': 'Price: Ascending',
        'price:desc': 'Price: Descending',
        'name': 'A to Z',
      },
      pageSizes: [12, 24, 36, 48, 60],
      pageSizeSelectClass: 'form-select',
      sortSelectClass: 'form-select',
      facetToggleConfig: {
        toggledFacet: { field: 'Universal', term: 'True' },
        visibleIfFields: ['Make', 'Model', 'Year'],
      },
    },
    {
      name: 'SearchResult',
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'CategoryFacetPanel',
      type: 'FacetPanel',
      location: 'body.page-category .page .page-sidebar',
      template: 'FacetPanelContainer',
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'SearchBox',
      location: { replace: '.logo-search-bar form.form', class: 'form' },
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: '.mobile-search',
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      fields: baseFitmentFields,
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm_vehicle-widget',
      template: 'fitmentSearch/collapsibleVehicleWidget',
      fields: baseFitmentFields,
      columnBreakpoint: 850,
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
      columnBreakpoint: 700,
    },
    {
      name: 'Garage',
      location: { replace: '.my-garage', wrapper: 'a', class: 'fav-wishlist cm_garage-container' },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'SingleVehicleGarage',
      location: {
        replace: '#choose-vehicle',
        wrapper: 'button',
        class: 'cm_single-vehicle-garage',
      },
      template: 'fitmentSearch/singleVehicleGarage',
    },
    {
      name: 'FitmentTable',
      location: '#product_fitment',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: '.fit_note',
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'VerifyFitment_top',
      type: 'VehicleWidget',
      location: '.fit_note_top',
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialogVehicleSpecs',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
