
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function pageTitleRT () {
    return _createElement('h1', { 'className': 'page-heading' }, this.template === 'active' ? _createElement('div', {
        'className': 'cmTemplate_active',
        'key': '28'
    }, 'Search') : null, this.template === 'locked' ? _createElement('div', {
        'className': 'cmTemplate_locked',
        'key': '119'
    }, 'Search Results for') : null);
}
        export const componentNames = []